import React from 'react';
import { Footer, Header, SEO } from 'components';
import { SchemaProvider } from '../../context/schema';
import 'typeface-inter';
import '../../sass/global/styles.scss';
import './styles.scss';

const Layout = props => {
  const { children, seo, location } = props;
  return (
    <SchemaProvider>
      <Header />
      <main>
        <SEO {...seo} location={location} />
        {children}
      </main>
      <Footer />
    </SchemaProvider>
  );
};

export default Layout;
