import React from 'react';
import OnVisible from 'react-on-visible';
import { Image, Link, BackgroundLogo, StarReview, ProductHunt } from 'components';
import './styles.scss';

const TwoCols = props => {
  const { data } = props;
  const { primary } = data;
  const {
    background_colour: bg,
    content,
    cta_link: ctaLink,
    cta_text: ctaText,
    image,
    image_left: imageLeft,
    tagline,
    tagline_icon: taglineIcon,
    title,
    show_background_logo: showBg,
    show_rating: showRating,
    product_hunt: showProductHunt,
  } = primary;

  // content checks
  const contentHtml = content?.html;
  const titleHtml = title?.html;
  const hasCta = ctaLink?.url && ctaText?.text;
  const taglineHtml = tagline?.html;
  const hasImage = image?.url || image?.localFile;
  // apply classes
  const backgroundClass = bg === 'White' ? 'white' : '';
  const alignImage = imageLeft ? 'left-align' : '';

  return (
    <OnVisible className={`two-cols ${backgroundClass} ${alignImage}`} wrappingElement="section">
      {showBg && <BackgroundLogo />}
      <div className="wrapper">
        <div className="two-cols-text">
          {taglineHtml && (
            <div className="two-cols-tagline-container">
              {taglineIcon && <Image className="two-cols-tagline-image" image={taglineIcon} />}
              <div className="two-cols-tagline" dangerouslySetInnerHTML={{ __html: taglineHtml }} />
            </div>
          )}
          {titleHtml && <div className="two-cols-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
          {contentHtml && <div className="two-cols-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />}
          {hasCta && (
            <Link className="button" to={ctaLink.url}>
              {ctaText.text}
            </Link>
          )}
          {showProductHunt && (
            <div className="two-cols-product-hunt">
              <ProductHunt />
            </div>
          )}
          {showRating && <StarReview />}
        </div>
        {hasImage && <Image className="two-cols-image" image={image} />}
      </div>
    </OnVisible>
  );
};

export default TwoCols;
