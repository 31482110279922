import React, { useState, useEffect } from 'react';
import { Link } from 'components';
import useSchema from '../../hooks/useSchema';
import './styles.scss';

const FaqAccordion = props => {
  const { data } = props;
  const { primary, items } = data;

  const { title, content, cta_link: ctaLink, cta_text: ctaText, background_colour: backgroundColor } = primary;

  const [openIndexes, setOpenIndexes] = useState([0]);

  const titleHtml = title?.html;
  const ctaTextText = ctaText?.text;
  const ctaLinkUrl = ctaLink?.url;

  const toggleFaq = (event, index) => {
    event.preventDefault();
    const indexOfExisting = openIndexes.indexOf(index);
    const clonedArr = [...openIndexes];
    if (indexOfExisting === -1) {
      clonedArr.push(index);
    } else {
      clonedArr.splice(indexOfExisting, 1);
    }
    setOpenIndexes(clonedArr);
  };

  const schema = useSchema();
  const { pushFAQ } = schema;

  useEffect(() => {
    const faqArr = items.map(item => {
      const { question, answer } = item;
      const qaObject = {
        '@type': 'Question',
        name: question?.text,
        acceptedAnswer: {
          '@type': 'Answer',
          text: answer?.html,
        },
      };
      return qaObject;
    });
    pushFAQ(faqArr);
  }, []);

  const bgColour = backgroundColor === 'Aqua' ? 'aqua-background' : '';

  return (
    <section className={`faq ${bgColour}`}>
      <div className="wrapper">
        {titleHtml && <div className="faq-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        {content && content.html && <div className="faq-content" dangerouslySetInnerHTML={{ __html: content.html }} />}
        <div className="faq-accordion">
          {items.map((item, index) => (
            <FaqItem
              key={item.question.text}
              toggleFaq={toggleFaq}
              openIndexes={openIndexes}
              itemIndex={index}
              {...item}
            />
          ))}
        </div>
        {ctaTextText && (
          <Link to={ctaLinkUrl} className="button square">
            {ctaTextText}
          </Link>
        )}
      </div>
      {/* <div dangerouslySetInnerHTML={{ __html: schema }} /> */}
    </section>
  );
};

const FaqItem = props => {
  const { question, answer, toggleFaq, openIndexes, itemIndex } = props;

  const active = openIndexes.indexOf(itemIndex) !== -1;

  return (
    <div className={`faq-accordion-item ${active ? 'active' : 'inactive'}`}>
      <a className="faq-accordion-question" href="#toggle" onClick={event => toggleFaq(event, itemIndex)}>
        <span className="faq-accordion-question-title">{question.text}</span>
        <button className={`plus-minus ${active ? 'active' : 'inactive'}`} type="button">
          Toggle
        </button>
      </a>
      <div className="faq-accordion-answer" dangerouslySetInnerHTML={{ __html: answer.html }} />
    </div>
  );
};

export default FaqAccordion;
