import React, { Fragment } from 'react';
import { Link, Image, Logo } from 'components';
import { graphql, useStaticQuery } from 'gatsby';

import EmailSignUp from './EmailSignUp';
import facebook from '../../images/facebook.svg';
import linkedIn from '../../images/linkedin.svg';
import twitter from '../../images/twitter.svg';
import instagram from '../../images/instagram.svg';
import tiktok from '../../images/tiktok.svg';
import './styles.scss';

const Footer = () => {
  const footerQuery = graphql`
    query FooterLinks {
      prismicSettings {
        data {
          footer_nav_links {
            link_text {
              text
            }
            link_url {
              url
            }
          }
        }
      }
    }
  `;

  const data = useStaticQuery(footerQuery);
  const { prismicSettings } = data;
  const { data: navData } = prismicSettings;
  const { footer_nav_links: navLinks } = navData;

  return (
    <footer className="footer">
      <div className="wrapper">
        <div className="footer-actions">
          {/* LOGO */}
          <div className="footer-actions-logo">
            <Logo />
          </div>
          <p className="footer-actions-cta">Sign up for special offers</p>
          {/* MAILCHIMP */}
          <EmailSignUp />
          <p className="footer-actions-disclaimer">By signing up you agree to our privacy policy</p>
          {/* SOCIALS */}
          <div className="footer-actions-socials">
            <Link to="https://www.facebook.com/similarinc" className="footer-actions-social-link">
              <Image image={{ url: facebook }} />
            </Link>
            <Link to="https://www.linkedin.com/company/similar/" className="footer-actions-social-link">
              <Image image={{ url: linkedIn }} />
            </Link>
            <Link to="https://twitter.com/SimilarInc" className="footer-actions-social-link">
              <Image image={{ url: twitter }} />
            </Link>
            <Link to="https://www.instagram.com/similar_inc/" className="footer-actions-social-link">
              <Image image={{ url: instagram }} />
            </Link>
            <Link to="https://www.tiktok.com/@similarinc" className="footer-actions-social-link">
              <Image image={{ url: tiktok }} />
            </Link>
          </div>
        </div>
        {/* // FOOTER LINKS */}
        <div className="footer-links-container">
          <div className="sitemap">
            {navLinks && navLinks.length > 0 && (
              <div className="footer-links">
                {navLinks.map(link => {
                  const { link_text: text, link_url: to } = link;
                  return (
                    <Fragment key={text?.text}>
                      <Link className="footer-nav-link" to={to?.url}>
                        {text?.text}
                      </Link>
                    </Fragment>
                  );
                })}
              </div>
            )}
            <span className="legal">© {new Date().getFullYear()} Anamantic Pty Ltd</span>
            <div className="footer-terms-policy">
              <Link to="/privacy-policy">Privacy Policy</Link>
              <span> | </span>
              <Link to="/terms-and-conditions">Terms of use</Link>
              <span> | </span>
              <Link to="/retailers">For Retailers</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
