import React from 'react';
import OnVisible from 'react-on-visible';
import { Link, BackgroundLogo } from 'components';
import './styles.scss';

const TextBlock = props => {
  const { data } = props;
  const { primary } = data;
  const {
    background_colour: bg,
    content,
    cta_link: ctaLink,
    cta_text: ctaText,
    title,
    show_logo_background: showBg,
  } = primary;

  const contentHtml = content?.html;
  const titleHtml = title?.html;
  const hasCta = ctaLink?.url && ctaText?.text;

  const backgroundClass = bg === 'White' ? 'white' : '';

  return (
    <OnVisible className={`text-block ${backgroundClass}`} wrappingElement="section">
      {showBg && <BackgroundLogo />}
      <div className="wrapper">
        {titleHtml && <div className="text-block-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        {contentHtml && <div className="text-block-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />}
        {hasCta && (
          <Link className="button" to={ctaLink.url}>
            {ctaText.text}
          </Link>
        )}
      </div>
    </OnVisible>
  );
};

export default TextBlock;
