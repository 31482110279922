import React from 'react';
import { Image, Link } from 'components';
import stars from '../../images/stars.svg';
import './styles.scss';

const StarReview = props => {
  return (
    <div className="star-review">
      <Image className="star-review-stars" image={{url: stars}} />
      <span>Rated 4.7/5 on <Link to='#' className="star-review-link">Google Chrome Store</Link></span>
    </div>
  );
};

export default StarReview;
