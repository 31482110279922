import React from 'react';
import { Image } from 'components';
import './styles.scss';

const Testimonials = props => {
  const { data } = props;
  const { primary, items } = data;
  const { title, image, name } = primary;

  const titleHtml = title?.html;
  const nameHtml = name?.html;
  const hasImage = image?.url || image?.localFile

  return (
    <section className="testimonials">
      <div className="wrapper">
        {titleHtml && <div className="testimonials-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        <div className="testimonials-images-container">
          {hasImage && <Image className="testimonial-image" image={image} />}
          {nameHtml && <div className="testimonials-name" dangerouslySetInnerHTML={{ __html: nameHtml }} />}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
