import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { Image } from 'components';
import letter from '../../../images/email.svg';

// ERROR COMPONENT
const ErrorMessage = ({ submitError }) => {
  if (!submitError) return null;
  const alreadySubscribed = submitError.indexOf('already subscribed') || submitError.indexOf('too many requests');
  const message = alreadySubscribed
    ? "Looks like you're already subscribed to our mailing list."
    : 'Uh oh! Something went wrong!';
  return <span className="newsletter-signup-error">{message}</span>;
};

const SuccessMessage = ({ submitSuccess }) => {
  if (!submitSuccess) return null;
  return (
    <div className={`newsletter-signup-success ${submitSuccess ? 'active' : ''}`}>
      <span>Thanks for joining the Similar newsletter!</span>
    </div>
  );
};

const EmailSignUp = () => {
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [email, setEmail] = useState('');

  const handleChange = event => {
    event.preventDefault();
    setEmail(event.target.value);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setSubmitting(true);
    setSubmitError(false);
    setSubmitSuccess(false);
    const result = await addToMailchimp(email);
    if (result.result === 'success') {
      setSubmitting(false);
      setSubmitSuccess(true);
      setEmail('');
    } else {
      setSubmitting(false);
      setSubmitError(result.msg);
    }
  };

  return (
    <div className="footer-actions-email">
      <ErrorMessage submitError={submitError} />
      <SuccessMessage submitSuccess={submitSuccess} />
      <form className="footer-actions-email-form" onSubmit={handleSubmit}>
        <div className="footer-actions-input-container">
          <Image image={{ url: letter }} className="footer-actions-email-image" />
          <input
            type="email"
            className="footer-actions-email-input"
            id="email"
            value={email}
            name="email"
            onChange={event => handleChange(event)}
            required
            placeholder="Enter email"
          />
        </div>
        <button type="submit" className="footer-actions-email-button button" aria-label="Send" disabled={submitting}>
          {submitSuccess ? 'Joined!' : 'Join'}
        </button>
      </form>
    </div>
  );
};

export default EmailSignUp;
