import React from 'react';
import { Logo, ProductHunt, Link } from 'components';
import './styles.scss';

// const navigationLinks = [{ to: '#', text: 'Add to chrome', className: '' }];

const Header = () => (
  <header className="header">
    <div className="wrapper">
      <Logo />
      <div className="header-actions">
        <Link className="contact" to="/retailers">
          For Retailers
        </Link>
        <Link
          to="https://chrome.google.com/webstore/detail/similar-the-best-price-on/aodleelklmlppabmpdecbogjogklhlgi"
          className="button"
        >
          Add to Chrome
        </Link>
        <ProductHunt />
      </div>
    </div>
  </header>
);

export default Header;
