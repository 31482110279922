import React from 'react';
import { Image } from 'components';
import bgLogo from '../../images/bg_logo.svg';
import './styles.scss';

const BackgroundLogo = props => {
  return (
    <Image className="background-logo" image={{url: bgLogo}} />
  );
};

export default BackgroundLogo;
