import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

const Link = props => {
  const {
    children,
    className,
    to,
    title,
    target = '_blank',
    tabIndex,
    onClick = () => {},
    onMouseOver = () => {},
    onMouseOut = () => {},
  } = props;

  const isExternal = (to && to.indexOf('http') !== -1) || (to && to[0] === '#');

  const similarUrl = 'https://similarinc.com';
  const isSimilarLink = to && to.indexOf(similarUrl) !== -1;

  if (isExternal && !isSimilarLink) {
    return (
      <a
        href={to}
        className={className || ''}
        title={title || null}
        target={target}
        onClick={onClick}
        rel="nofollow noopener noreferrer"
      >
        {children}
      </a>
    );
  }

  const withTrailingSlash = `${to}${to.endsWith('/') ? '' : '/'}`;
  const linkTo = isSimilarLink ? withTrailingSlash.replace(similarUrl, '') : withTrailingSlash;

  return (
    <GatsbyLink
      to={linkTo}
      className={className || ''}
      title={title || null}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      tabIndex={tabIndex}
    >
      {children}
    </GatsbyLink>
  );
};

export default Link;
