import React from 'react';
import OnVisible from 'react-on-visible';
import './styles.scss';

const Wysiwyg = props => {
  const { data } = props;
  const { primary } = data;
  const { content, title } = primary;

  const contentHtml = content?.html;
  const titleHtml = title?.html;

  return (
    <OnVisible className="wysiwyg" wrappingElement="section">
      <div className="wrapper">
        {titleHtml && <div className="wysiwyg-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        {contentHtml && <div className="wysiwyg-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />}
      </div>
    </OnVisible>
  );
};

export default Wysiwyg;
