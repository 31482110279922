import React from 'react';
import PostGrid from '../PostGrid/index.jsx';
import './styles.scss';

const CategoryPosts = props => {
  const { posts, title } = props;

  if (!posts || posts.length < 1) {
    return (
      <section className="category-posts is-empty">
        <div className="wrapper">
          <p className="category-posts-empty">No posts found</p>
        </div>
      </section>
    );
  }

  return (
    <section className="category-posts">
      <PostGrid posts={posts} title={title} showExcerpt />
    </section>
  );
};

export default CategoryPosts;
