import React, { useState, useEffect } from 'react';
import OnVisible from 'react-on-visible';
import ReactPlayer from 'react-player';
import { Modal, Video, Image } from 'components';
import useSchema from '../../hooks/useSchema';
import playIcon from '../../images/play_button_circled.svg';
import './styles.scss';

const VideoSlice = props => {
  const { data, isPage } = props;
  const { primary, __typename } = data;
  const {
    title,
    content,
    video,
    video_placeholder: placeholder,
    youtube_link: youtube,
    schema_description: description,
    schema_name: name,
    schema_upload_date: uploadDate,
  } = primary;

  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const youtubeUrl = youtube?.url;

  const [showModal, setShowModal] = useState(false);

  const openModal = event => {
    event.preventDefault();
    setShowModal(true);
  };

  const closeModal = event => {
    event.preventDefault();
    setShowModal(false);
  };

  const schema = useSchema();
  const { setVideoObject } = schema;
  const vidObj = {
    embedUrl: youtube?.url,
    thumbnailUrl: placeholder?.url,
    description: description?.text || 'Similar video',
    uploadDate: uploadDate || new Date(),
    name: name?.text || 'Similar Video',
  };

  useEffect(() => {
    setVideoObject(vidObj);
  }, []);

  const hasYoutube = youtube?.url ? 'has-youtube' : '';
  const hasVideo = video && video.url;
  if (!hasVideo && !hasYoutube) return null;
  // render variant
  const pageVariant = __typename === 'PrismicPageBodyVideo' ? 'page-variant' : '';

  return (
    <OnVisible className={`video-section ${pageVariant} ${hasYoutube}`} percent={40}>
      <div className={`${isPage ? 'wrapper' : ''}`}>
        {titleHtml && <div className="video-section-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        {contentHtml && <div className="video-section-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />}
        <div className="video-section-video">
          {hasVideo ? (
            <div
              role="img"
              onClick={youtubeUrl ? openModal : () => {}}
              className={`video-section-video-container ${youtubeUrl ? 'has-modal' : ''}`}
            >
              <Video
                key="Similar"
                id="similar"
                placeholder={placeholder && placeholder.localFile && placeholder.localFile.childImageSharp.fixed.base64}
                src={hasVideo}
              />
              {youtubeUrl && (
                <a href="#openmodal" className="video-section-play" onClick={openModal}>
                  <Image className="play-button" image={{ url: playIcon }} />
                </a>
              )}
            </div>
          ) : (
            <ReactPlayer
              className="react-player"
              url={youtubeUrl}
              volume={1}
              muted={false}
              config={{
                youtube: {
                  playerVars: { autoplay: 0, controls: 1 },
                },
              }}
            />
          )}
        </div>
      </div>
      {youtubeUrl && (
        <Modal active={showModal} closeModal={closeModal} className="iframe-modal">
          <div className="iframe-container">
            <ReactPlayer
              url={youtubeUrl}
              volume={1}
              muted={false}
              width="100%"
              height="100%"
              config={{
                youtube: {
                  playerVars: { autoplay: 1, controls: 1 },
                },
              }}
            />
          </div>
        </Modal>
      )}
    </OnVisible>
  );
};

export default VideoSlice;
