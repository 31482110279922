module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Similar', // Default Site Title used for SEO & PWA
  description: 'We actually search the internet for the best price.', // Default Site Decription used for SEO
  siteName: 'Similar', // Sitename for Facebook
  siteLanguage: 'en', // Language Tag on <html> element
  banner: '/logos/logo-1024.jpg', // Default OpenGraph image
  ogLanguage: 'en_US', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/images/similar-icon.png', // Used for manifest favicon generation
  shortName: 'Similar', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Similar', // Author for schemaORGJSONLD
  themeColor: '#FFFFFF',
  backgroundColor: '#FFFFFF',
  socialLinks: [
    'https://www.linkedin.com/company/similar/',
    'https://twitter.com/similarinc',
    'https://www.facebook.com/similarinc/',
    'https://www.instagram.com/similar_inc/',
  ],

  twitter: 'SimilarInc', // Twitter Username
  googleTagManagerId: 'GTM-KXS5QJN',
};
