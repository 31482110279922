import React from 'react';
import { Link, Button } from 'components';
import BlogPostCard from '../BlogPostCard/index.jsx';
import './styles.scss';

const PostGrid = props => {
  const { posts, className, title, showExcerpt, hasFeaturedPost } = props;
  if (!posts || posts.length < 1) return null;

  const vericalLayout = title === 'Latest Posts' ? 'latest-posts' : '';
  const category = title ? title.toLowerCase().replace(/ /g, '-') : null;
  const defaultLinkResolve = `/blog/${category}`;

  return (
    <section className={`post-grid ${className || ''}`} key={title}>
      <div className="wrapper">
        {title && (
          <div className="post-grid-header-container">
            <span className="post-grid-title">{title}</span>
            <Button to={defaultLinkResolve}>
              <span className="post-grid-view-all">View all posts</span>
            </Button>
          </div>
        )}
        <div className={`post-grid-container ${className || vericalLayout || ''}`}>
          {posts.length > 0 &&
            posts.map((post, index) => (
              <BlogPostCard
                key={post?.uid}
                data={post?.data}
                uid={post?.uid}
                className={className}
                index={index}
                showExcerpt={showExcerpt || (hasFeaturedPost && index === 0)}
              />
            ))}
        </div>
      </div>
    </section>
  );
};

export default PostGrid;
