import React from 'react';
import { Link } from 'components';
import './styles.scss';

const ProductHunt = () => (
  <Link
    className="product-hunt"
    to="https://www.producthunt.com/posts/similar-inc?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-similar-inc"
  >
    <img
      src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=303175&theme=light"
      alt="Similar Inc - The Decentralized Everything Store / Chrome Extension (Beta) | Product Hunt"
      width="250"
      height="54"
    />
  </Link>
);

export default ProductHunt;
